@import '@/styles/variables';

.edit-cell-type {
  padding: 0 20px;
  font-size: 20px;
  background-color: var(--color-white-15);

  &__table {
    padding-inline: 20px;
  }

  &__column {
    --min-column-width: 150px;
    min-width: var(--min-column-width);
  }

  &__header-title {
    @include run-design-table-header-labels;
    @include run-design-default-cell;
  }

  &__select-control {
    height: 55px;
  }

  &__select-menu-list {
    &#{&} {
      padding: 0;
    }
  }
}

.cell-content {
  @include run-design-default-cell;
  position: relative;

  &_name {
    @include run-design-cell-name;
  }

  &:has(&__name-block) {
    background-color: var(--color-white);
  }

  &__name-block {
    position: relative;
    z-index: var(--z-index, 1);
    display: -webkit-box;
    align-items: center;
    width: 100%;
    height: 55px;
    padding: 10px;
    overflow: hidden;
    font-size: var(--run-design-card-inputs-default-font-size);
    font-weight: normal;
    line-height: 1.15;
    text-align: left;
    text-overflow: ellipsis;
    text-wrap: balance;
    word-break: break-word;
    border-radius: 10px;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &_function {
    height: 55px;
    background-color: var(--color-white);
    border-radius: 10px;
  }
}
