@import '@/styles/variables';

.consumable-container {
  @include run-design-default-cell;
}

.consumable-wrap {
  width: 100%;
  border-radius: 8px;

  &:focus-visible {
    outline: 2px solid var(--focus-color);
    outline-offset: 2px;
  }
}

.consumable {
  position: relative;
  width: 100%;
  min-width: var(--run-design-cell-column-content-default-min-width);
  height: 55px;
  font-size: var(--consumable-font-size, var(--run-design-card-inputs-default-font-size));
  background-color: var(--consumable-bg-color, var(--color-white));
  border-radius: 10px;

  &__content {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 5px;
    align-items: center;
    height: 100%;
    padding-left: 10px;

    &:not(&._edition) {
      padding-right: 10px;
    }
  }

  &__waves {
    position: relative;
    z-index: 1;
  }

  &_preselected {
    --select-border-color: var(--light-color-gray-15);
    background-color: inherit;

    &:hover {
      --select-border-color: var(--color-black);
    }
  }

  &__name {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    max-height: calc(2em * 1.15);
    overflow: hidden;
    line-height: 1.15;
    text-align: left;
    text-overflow: ellipsis;
    text-wrap: balance;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__button {
    padding: unset;
  }
}

.consumable-header {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 5px;
  }

  &__wrapper._edition {
    --exemoffset: 37px;
  }

  &__text-block {
    @include run-design-table-header-labels;
  }

  &__ex-em {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80px;
    margin-right: var(--exemoffset, 10px);
  }
}

.preselected-consumable {
  &__plus-icon {
    width: 20px;
    height: 20px;
  }

  &__name {
    position: relative;
    top: 2px;
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: flex-start;
    height: calc(100% - 4px);
    padding-left: 16px;
  }
}

.consumable-option {
  &__wrapper {
    @include run-design-default-cell;

    cursor: pointer;
    transition: background-color 0.3s ease-out;

    &:hover {
      background-color: var(--color-yellow-40);
    }
  }

  &__item {
    background-color: transparent;
  }
}

.select-menu {
  padding-inline: 0 !important;
}
