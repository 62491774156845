.scan-time {
  display: flex;
  flex-direction: column;
  height: 54px;
  padding: 10px;
  padding-right: 0;
  font-family: SFMono, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-transform: lowercase;
  letter-spacing: 0.5px;
  white-space: nowrap;
  background-color: var(--color-black);

  &__hours {
    color: var(--light-color-gray-15)
  }

  &__min-sec {  
    color: var(--color-white)
  }
}