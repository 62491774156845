.schema-cell-killing {
  --incubation-bg-color: #CFF3FB;
  --scan-bg-color: #38DDFE;
  --step-scan-color: var(--color-black);

  position: relative;
  display: grid;
  grid-template-columns: subgrid;
  grid-row-start: var(--row, 1);
  grid-column-start: var(--column, 1);
  grid-column-end: var(--column-end, span 1);
  background-color: var(--incubation-bg-color);
  transition: background-color .3s ease-out;

  &::after {
    position: absolute;
    inset: 0;
    content: '';
    border: 2px solid transparent;
    transition: border-color .3s ease-out;
  }

  &:hover,
  &:active {
    &::after {
      border: 2px solid var(--scan-bg-color);
    }
  }

  &_current {
    --incubation-bg-color: var(--color-yellow-60);
    --scan-bg-color: var(--color-black-20);
    --step-scan-color: var(--color-white);
  }

  &_stain {
    position: absolute;
    bottom: 5px;
  }
}
