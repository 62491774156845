.lanes-plate {
  --template-progress-color-selected: var(--color-yellow-70);

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 470px;
  height: 335px;
  padding: 40px;
}

.border-wrapper {
  position: absolute;
  inset: 0;
}
