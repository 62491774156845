@import '@/styles/variables';

.edit-setting {
  &__waves {
    width: 100%;
    padding: 7px;
    font-size: 16px;
    background-color: var(--color-white);
  }
}

.selected-field {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 40px;

  &_from-start {
    justify-content: start;
  }

  &_no-padding {
    padding: 0;
  }

  &__reset-btn {
    --button-color: var(--color-white);
    width: 100px;
    height: 100%;
    font-size: var(--run-design-card-inputs-default-font-size);
    font-weight: normal;
    color: var(--color-black);
    border: none;
    box-shadow: inset 0 0 0 2px var(--color-black);
    transition: box-shadow .3s ease-out, background-color .3s ease-out;

    &:hover:enabled,
    &:active:enabled {
      background-color: var(--color-white);
    }

    &_disabled {
      box-shadow: none;
    }
  }
}

.select {
  --select-font-size: var(--run-design-card-inputs-default-font-size);

  min-width: 94px;
  height: 100%;
  background-color: var(--color-white);
  border-radius: 10px;

  &__control {
    height: 55px;
  }

  &__menu {
    --select-menu-margin-block: -50px -50px;

    &_waves {
      --select-menu-margin-block: 5px;
    }
  }

  &__menu-list {
    &#{&} {
      padding: 0;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    height: 35px;
  }

  & &__waves-value-container {
    display: flex ;
    flex-wrap: nowrap;
    width: 100% ;
    height: 55px;
    padding: 0 ;
  }

  &__waves-menu-list{
    width: max-content;
    padding: 0 !important;
  }
}

.cell-name-select {
  @include run-design-cell-name;
}

.magnification {
  width: 185px;
}


.error {
  box-shadow: 0 0 2px 1px var(--color-red) !important;
}

.number-field {
  &_right-gap {
    padding-right: 40px;
  }

  &__container {
    --field-height: 55px;
  }
}

