.default-card {
  --default-bg-color: #53E2FF;
  --scan-bg-color: var(--color-gray-10);
  --default-color: var(--color-black);
  --default-bg-color-hover: var(--color-yellow-60);
  --scan-color: var(--color-white);
  --scan-bg-color-hover: #404040;

  &:hover {
    --default-bg-color: var(--default-bg-color-hover);
    --scan-bg-color: var(--scan-bg-color-hover);
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 10px 10px;
    color: var(--default-color);
    background-color: var(--default-bg-color);
    transition: background-color .3s ease-out;
  }

  &__scan {
    position: relative;
    display: var(--scan-display, flex);
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 10px 10px;
    color: var(--scan-color);
    background-color: var(--scan-bg-color);
    transition: background-color .3s ease-out;
  }
}
