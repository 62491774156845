.main-block {
  display: flex;
  flex-grow: 1;
  margin-top: 20px;
  overflow: hidden;
  padding-inline: 20px;

  &__column {
    display: flex;
    flex: 1;
    padding-bottom: 18px;
    overflow: hidden;
  }

  &__column,
  &__inner-column {
    position: relative;
    display: flex;

    &:not(&:last-child)::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 20px;
      z-index: 2;
      content: '';
      border-right: 1px solid var(--color-gray-80);
    }
  }

  &__inner-column {
    --header-color: var(--color-black);
    --header-bg-color: var(--color-yellow-60);

    flex-direction: column;
    flex-grow: 1;

    &_scan {
      --header-color: var(--color-white);
      --header-bg-color: var(--color-black-20);
    }

    &_max-limited {
      max-width: 300px;
    }
  }

  &__header-list {
    position: sticky;
    top: 0;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(var(--sub-column-count, 1), minmax(0, 1fr));
    height: 110px;
    background-color: var(--header-bg-color);
  }

  &__header-list:has(&__header-elem:nth-child(2)) {
    --sub-column-count: 2;
  }

  &__header-list:has(&__header-elem:nth-child(3)) {
    --sub-column-count: 3;
  }

  &__header-elem {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    height: 110px;
    padding: 16px 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    color: var(--header-color);

    & + & {
      border-left: 1px solid var(--color-gray-80);
    }

    svg + svg {
      margin-left: 20px;
    }
  }

  &__content {
    display: grid;
    flex-grow: 1;
    grid-template-columns: repeat(var(--sub-column-count, 1), minmax(0, 1fr));
    overflow: auto;
  }

  &__content:has(&__sub-column:nth-child(2)) {
    --sub-column-count: 2;
  }

  &__content:has(&__sub-column:nth-child(3)) {
    --sub-column-count: 3;
  }

  &__sub-column {
    position: relative;

    &:not(&:last-child, &:only-child)::before {
      position: absolute;
      top: 20px;
      right: 0;
      bottom: 20px;
      content: '';
      border-right: 1px solid var(--color-gray-80);
    }
  }

  &__block {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 20px;

    &:first-of-type {
      height: 130px;
    }

    &:not(&:last-child)::before {
      position: absolute;
      right: 20px;
      bottom: 0;
      left: 20px;
      content: '';
      border-bottom: 1px solid var(--color-gray-80);
    }

    &_center-content {
      align-items: center;
      justify-content: center;
    }
  }

  &__block-title {
    grid-column-end: span 2;
    padding-bottom: 16px;
    overflow: hidden;
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-overflow: ellipsis;
    text-transform: uppercase;
    letter-spacing: .5px;
    white-space: nowrap;

    &_no-gap {
      padding: 0;
    }

    &_with-wrap {
      white-space: initial;
    }
  }

  &__block-text {
    font-size: 20px;
    font-weight: 600;
    color: var(--color-black-20);
  }

  &__text-info {
    font-size: 20px;
    letter-spacing: 0.25px;
  }

  &__group {
    max-width: 100%;

    &_time {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px;
    }

    &_scan-count {
      width: 100px;
    }

    &_baseline-scan {
      width: 50%;
    }
  }

  &__title-group {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-bottom: 16px;
  }

  &__select {
    --select-bg-color: var(--color-white-15);
    --select-border-color: var(--color-white-15);
    --select-border-width: 1px;
    --select-font-size: 20px;

    width: 280px;
    font-weight: 500;
    color: var(--color-black);

    &_narrow {
      width: 100px;
    }
  }

  &__select-control {
    height: 55px;
    padding: 0 5px;
  }

  &__select-menu {
    --select-menu-margin-block: -55px;
  }

  &__select-menu-list {
    &#{&} {
      padding: 0;
    }
  }

  &__input {
    height: 55px;
    font-size: 20px;
    font-weight: 500;
    background-color: var(--color-white-15);
    border: none;

    &_disabled {
      background-color: var(--color-white);
      border: 1px solid var(--color-white-5);
    }
  }

  &__scan-type {
    display: block;
    overflow: hidden;
    font-size: 20px;
    font-weight: 500;
    color: var(--color-black-5);
    text-overflow: ellipsis;
    white-space: nowrap;

    &_secondary {
      font-size: 19px;
      color: var(--color-black-20);
      letter-spacing: -.57px;
    }
  }

  &__toggle-box {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 45px;

    &_bigger-gap {
      gap: 20px;
    }
  }

  &__info {
    padding: 6px 16px 8px;
    background: var(--color-white-15);
    border-radius: 20px;
  }

  &__checkbox-label {
    font-size: 20px;
    color: var(--color-black);
  }
}
