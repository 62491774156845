.step-settings {
  &__header {
    display: grid;
    grid-template-columns: 65px 1fr 265px;
    color: var(--color-black-20);


    &__burger {
      align-self: flex-start;
      margin-right: 10px;
    }

    &__components {
      display: flex;
      overflow: auto;
    }
  }
}