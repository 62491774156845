.components-swiper {
  gap: 1px;
  overflow: hidden;
  background-color: var(--color-black);
  border-top-left-radius: 10px;

  &__content {
    flex-grow: 1;
    gap: 1px;
    background: linear-gradient(to bottom, var(--color-gray-30) 50%, var(--color-black) 50%);
  }

  &__arrow {
    display: flex;
  }
}

.component {
  &__swiper-item {
    flex-grow: 1;
  }

  &__dnd-item {
    flex-grow: 1;
  }

  &__timeline-item {
    &._drop-before {
      box-shadow: inset 10px 0 15px -5px var(--color-white-10);
    }

    &._drop-after {
      box-shadow: inset -10px 0 15px -5px var(--color-white-10);
    }
  }
}
