.checkbox {
  // border
  --checkbox-border-color: var(--theme-checkbox-border-color, var(--color-gray-20));
  --checkbox-border-color-hover: var(--color-white);
  --checkbox-border-color-checked: var(--color-white);
  --checkbox-border-color-indeterminate: var(--checkbox-border-color-checked);
  --checkbox-border-color-disabled: var(--theme-checkbox-border-color-disabled, var(--color-gray-40));

  // background
  --checkbox-bg-color: transparent;
  --checkbox-bg-color-hover: transparent;
  --checkbox-bg-color-checked: var(--color-white);
  --checkbox-bg-color-indeterminate: var(--checkbox-bg-color-checked);
  --checkbox-bg-color-disabled: transparent;

  // icon
  --checkbox-icon-color: var(--color-black-40);
  --checkbox-icon-color-hover: var(--color-black-40);
  --checkbox-icon-color-checked: var(--color-black-40);
  --checkbox-icon-color-indeterminate: var(--checkbox-icon-color-checked);
  --checkbox-icon-color-disabled: var(--color-black-40);

  // text
  --checkbox-label-color: var(--theme-checkbox-label-color, var(--color-gray-20));
  --checkbox-label-color-hover: var(--theme-checkbox-label-color-hover, var(--color-white));
  --checkbox-label-color-disabled: var(--theme-checkbox-label-color-disabled, var(--color-gray-20));

  // switch
  --checkbox-color-switch-checked: var(--theme-checkbox-bg-color-switch-checked, var(--color-gray-50));
  --checkbox-color-slider-checked: var(--theme-checkbox-bg-color-slider-checked, var(--color-yellow-40));

  --checkbox-color-switch-unchecked: var(--theme-checkbox-color-switch-unchecked, var(--light-gray));
  --checkbox-color-slider-unchecked: var(--theme-checkbox-bg-color-slider-unchecked, var(--color-white-20));

  --checkbox-color-switch-disabled: var(--theme-checkbox-color-switch-disabled, var(--color-gray-10));
  --checkbox-color-slider-disabled: var(--theme-checkbox-bg-color-slider-disabled, var(--color-gray-40));

  --checkbox-color-switch-checked-disabled: var(--theme-checkbox-color-switch-disabled, var(--color-gray-20));
  --checkbox-color-slider-checked-disabled: var(
    --theme-checkbox-bg-color-slider-checked-disabled,
    var(--color-gray-20)
  );

  position: relative;
  display: flex;
  align-items: center;
  min-width: 20px;
  height: 20px;
  cursor: pointer;
  transition: color 0.3s;

  &:has(:disabled) {
    cursor: not-allowed;
  }

  &_left-label {
    flex-direction: row-reverse;
  }

  &:hover:enabled {
    --checkbox-border-color: var(--checkbox-border-color-hover);
    --checkbox-bg-color: var(--checkbox-bg-color-hover);
    --checkbox-label-color: var(--checkbox-label-color-hover);

    color: var(--color-white);
  }

  &__input {
    display: grid;
    flex-shrink: 0;
    place-content: center;
    width: 20px;
    height: 20px;
    margin: 0;
    font: inherit;
    color: var(--checkbox-label-color);
    cursor: pointer;
    background-color: var(--checkbox-bg-color);
    border: 2px solid var(--checkbox-border-color);
    border-radius: 6px;
    transition: background-color 0.3s var(--cubic-ease-out-expo), border-color 0.3s var(--cubic-ease-out-expo),
      color 0.3s var(--cubic-ease-out-expo);
    appearance: none;

    &:checked {
      --checkbox-border-color: var(--checkbox-border-color-checked);
      --checkbox-bg-color: var(--checkbox-bg-color-checked);
    }

    &_indeterminate {
      --checkbox-border-color: var(--checkbox-border-color-indeterminate);
      --checkbox-bg-color: var(--checkbox-bg-color-indeterminate);
    }

    &:disabled {
      --checkbox-border-color: var(--checkbox-border-color-disabled);
      --checkbox-bg-color-checked: var(--checkbox-border-color-disabled);
      cursor: not-allowed;
    }
  }

  &:hover:enabled &__input:checked,
  &:hover:enabled &__input_indeterminate {
    --checkbox-border-color: var(--checkbox-border-color-hover);
  }

  &__icon {
    position: absolute;
    width: 20px;
    height: 20px;
    color: var(--checkbox-icon-color);
    pointer-events: none;
  }

  &__input:checked + &__icon {
    color: var(--checkbox-icon-color-checked);
  }

  &__input_indeterminate + &__icon {
    color: var(--checkbox-icon-color-indeterminate);
  }

  &__input[disabled] + &__icon {
    color: var(--checkbox-icon-color-disabled);
  }

  &__label {
    padding-left: 10px;
    overflow: hidden;
    font-size: 16px;
    color: var(--checkbox-label-color);
    text-overflow: ellipsis;
    white-space: nowrap;

    &_left {
      padding-right: 10px;
      padding-left: 0;
    }

    &_slider {
      margin-left: calc(var(--slider-width, 50px) + 10px);
      font-size: 16px;
      color: var(--checkbox-label-color);
      white-space: nowrap;
    }

    &_checked {
      color: var(--checkbox-label-color-hover);
    }

    &_disabled {
      --checkbox-label-color: var(--checkbox-label-color-disabled);
      --checkbox-label-color-hover: var(--checkbox-label-color-disabled);

      cursor: not-allowed;
    }

    &_multiline {
      line-height: 1.2;
      white-space: normal;
    }
  }

  &_switch {
    --switch-size: 18px;
    --slider-width: 50px;
    --offset-x: 4px;

    height: 26px;

    input {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      border: 0;
    }
  }

  &.run-design {
    // switch
    --theme-checkbox-bg-color-switch-checked: var(--color-black);
    --theme-checkbox-bg-color-slider-checked: var(--color-yellow-60);
    --theme-checkbox-color-switch-unchecked: var(--color-white);
    --theme-checkbox-bg-color-slider-unchecked: var(--color-white);
    --theme-checkbox-bg-color-slider-disabled: var(--color-white);
    --theme-checkbox-border-color-disabled: var(--color-gray-70);

    --switch-size: 20px;
    --slider-width: 55px;
    --offset-x: 5px;
  }

  &.run-design-table {
    // switch
    --theme-checkbox-bg-color-switch-checked: var(--color-white);
    --theme-checkbox-bg-color-slider-checked: var(--color-black);
    --theme-checkbox-color-switch-unchecked: var(--color-white);
    --theme-checkbox-bg-color-slider-unchecked: var(--color-black);
  }

  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--slider-width);
    height: 26px;
    cursor: pointer;
    background-color: var(--checkbox-color-slider-unchecked);
    border-radius: 34px;
    transition: 0.4s;

    &::before {
      position: absolute;
      top: 50%;
      left: 1px;
      width: var(--switch-size);
      height: var(--switch-size);
      content: '';
      background-color: var(--checkbox-color-switch-unchecked);
      border-radius: 50%;
      transition: translate 0.3s ease-out, background-color 0.3s ease-out;
      translate: var(--slider-x, var(--offset-x)) -50%;
    }
  }

  &__input:disabled + &__slider {
    --switch-border-color: var(--color-gray-70);
    --switch-thumb-border-color: var(--color-gray-70);
    --switch-thumb-bg-color: var(--color-white);
    --checkbox-color-switch-disabled: var(--color-white);

    &::before {
      background-color: var(--color-white);
    }
  }

  &.run-design &__slider {
    height: 30px;
    border: 2px solid var(--switch-border-color, var(--color-black));
    transition: border-color 0.3s ease-out, background-color 0.3s ease-out;

    &::before {
      background-color: var(--switch-thumb-bg-color, transparent);
      border: 2px solid var(--switch-thumb-border-color, var(--color-black));
    }
  }
}

.checkbox.light {
  // border
  --checkbox-border-color: var(--theme-checkbox-border-color, var(--light-color-gray-15));
  --checkbox-border-color-hover: var(--light-gray);
  --checkbox-border-color-indeterminate: var(--checkbox-bg-color-checked);
  --checkbox-border-color-checked: var(--checkbox-bg-color-checked);
  --checkbox-border-color-disabled: var(--theme-checkbox-border-color-disabled, var(--light-color-gray-15));

  // background
  --checkbox-bg-color-checked: var(--color-black-20);

  // icon
  --checkbox-icon-color: var(--color-white);
  --checkbox-icon-color-hover: var(--color-white);
  --checkbox-icon-color-checked: var(--color-white);
  --checkbox-icon-color-disabled: var(--color-white);

  // text
  --checkbox-label-color: var(--theme-checkbox-label-color, var(--color-black));
  --checkbox-label-color-hover: var(--theme-checkbox-label-color-hover, var(--color-black));
  --checkbox-label-color-disabled: var(--theme-checkbox-label-color-disabled, var(--light-color-gray-15));
}

.checkbox__slider_input {
  &:checked + .checkbox__slider {
    --switch-border-color: var(--color-yellow-60);
    background-color: var(--checkbox-color-slider-checked);
  }

  &:checked + .checkbox__slider::before {
    --slider-x: calc(var(--slider-width) - var(--switch-size, 0) - 2 * var(--offset-x, 0));

    background-color: var(--checkbox-color-switch-checked);
  }

  &:disabled + .checkbox__slider {
    --switch-border-color: var(--theme-checkbox-border-color-disabled);

    cursor: not-allowed;
    background-color: var(--checkbox-color-slider-disabled);
  }

  &:disabled + .checkbox__slider::before {
    background-color: var(--checkbox-color-switch-disabled);
  }

  &:checked:disabled + .checkbox__slider {
    cursor: not-allowed;
    background-color: var(--checkbox-color-slider-checked-disabled);
  }

  &:checked:disabled + .checkbox__slider::before {
    cursor: not-allowed;
    background-color: var(--checkbox-color-switch-checked-disabled);
  }
}
