.tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.tab-item {
  width: 215px;
  height: 70px;
  border: 2px solid var(--light-color-gray-15);
  transition: border-color 0.3s ease-out, background-color 0.3s ease-out;

  &:last-of-type {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  &:first-of-type {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }

  &:hover:enabled {
    background-color: var(--color-yellow-70);
    border-color: var(--color-black);
  }


  &._selected {
    border-color: var(--color-black);
  }

  &._disabled {
    cursor: not-allowed;
  }
}