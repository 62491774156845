.flow-cell-settings {
  &__selects {
    display: flex;
    gap: 100px;
    align-items: center;
    padding: 20px 10px;
    border-bottom: 1px solid var(--color-gray-80);
  }

  &__select-type {
    display: grid;
    grid-template-columns: 140px minmax(250px, 540px);
    align-items: center;
  }

  &__lanes-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
  }

  &__samples-container {
    display: grid;
    grid-template-columns: 55px 1fr;
    gap: 30px;
    width: 550px;
    margin-top: 60px;
    border-left: 1px solid var(--color-black);
    padding-inline: 30px;
  }
}