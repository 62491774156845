@property --scan-bg-color {
  syntax: '<color>';
  initial-value: #808080;
  inherits: true;
}

.cell-killing-card {
  --scan-display: block;
  --default-bg-color: #53E2FF;
  --default-bg-color-hover: var(--color-yellow-60);
  --scan-bg-color-hover: #404040;
  --default-color: var(--color-black);
  --scan-color: var(--color-white);


  position: relative;
  display: grid;
  grid-template-rows: subgrid;
  grid-template-columns: subgrid;
  grid-row: var(--row, 1);
  grid-column-start: var(--column, 1);
  grid-column-end: var(--column-end, span 1);
  background-color: var(--default-bg-color);
  transition: background-color .3s ease-out, --scan-bg-color .3s ease-in-out;

  &:hover {
    --default-bg-color: var(--default-bg-color-hover);
    --scan-bg-color: #404040;
  }

  &__stain {
    position: absolute;
    bottom: 10px;
    left: calc(10px + 58px * var(--icon-count));
    z-index: 1;
    content: '';
  }

  &__scan {
    position: relative;
      z-index: 1;
      height: 100%;
      color: var(--scan-color);

    &::before {
      position: absolute;
      inset: 0;
      pointer-events: none;
      content: '';

      //noinspection CssInvalidFunction
      background-image: repeating-conic-gradient(var(--scan-bg-color) 0% 25%, transparent 0% 50%);
      background-size: 6px 6px;
      mix-blend-mode: multiply;
    }

    & > div {
      position:relative;
    }
  }

  &__part:last-child &__scan {
    background-color: var(--scan-bg-color);
  }
}
