@import '@/styles/variables';

.loader {
  @include loader;

  &__wrapper {
    height: 100%;
  }
}

.summary {
  --bg-colour: var(--color-white);
  --border-color: var(--light-color-gray-10);
  display: grid;
  grid-template-columns: 1fr var(--sidebar-width);
  width: 100%;
  padding: 0 0 0 20px;
  background-color: var(--bg-colour);
  border-radius: 20px;

  &__content {
    position: relative;
    padding: 0 0 70px;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      content: '';
      background-color: var(--border-color);
    }
  }

  :global(#images-scroll-area) {
    padding-bottom: 70px;
  }
}

.flow-data {
  --progress-colour: var(--color-white-20);
  --progress-colour-selected: var(--color-yellow-40);
  --text-color: var(--light-gray);
  --text-color-selected: var(--color-black);
  --border-color: var(--light-color-gray-10);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 40px;

  &__flowcell {
    position: relative;
    flex: 50%;
    padding-right: 20px;

    &::after {
      position: absolute;
      top: 20px;
      right: 0;
      bottom: 0;
      width: 1px;
      content: '';
      background-color: var(--border-color);
    }
  }

  &__summary {
    flex: 50%;
    padding: 0 20px;
  }
}

.statistics-headline {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding-block: 12px;
  margin-bottom: 15px;
}

.headline {
  position: relative;

  &__title {
    flex: 1;
    font-size: 17px;
    font-weight: 600;
    line-height: 30px;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background-color: var(--light-color-gray-10);
    opacity: .9;
  }
}

.chart-statistics {
  --border-color: var(--light-color-gray-10);

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: solid 1px var(--border-color);

    &:first-child {
      padding-top: 0;
      margin-top: -5px;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.graphic {
  --border-color: var(--light-color-gray-10);

  padding: 20px;
  margin-top: 60px;
  margin-right: 20px;
  border: 1px solid var(--border-color);

  &__control-panel {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: '';
      background-color: var(--border-color);
      opacity: .9;
    }
  }

  &__title {
    font-weight: 600;
  }

  &__chart {
    width: 100%;
    height: var(--graphic-height, 600px);
    overflow: hidden;

    &_no-data {
      height: 100%;
    }
  }
}

.chart__no-data-found {
  padding-top: 20px;
}
