@import '@/styles/variables';

.reagents-card {
  display: block;
}

.reagents-table {
  padding-inline: 20px;

  &__header-item {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.65px;
  }
}

.assay-reagents {
  &__wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__name {
    &_incubation {
      padding-right: 10px;
    }
  }

  &__modal-btn {
    display: flex;
    gap: 10px;
    color: var(--color-white);
  }

  &__badge {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    min-width: 30px;
    height: 30px;
    padding: 0 10px;
    font-size: 16px;

    &::before {
      position: absolute;
      left: -5px;
      width: 1px;
      height: 100%;
      content: '';
      background-color: var(--color-white);
    }

    &_counter {
      margin-right: 15px;
      border: 2px solid;
      border-radius: 15px;

      &::before {
        visibility: hidden;
      }
    }
  }

  &__header-btn {
    padding: 0;
    margin-left: 10px;
    border: none;

    &::before {
      left: -15px;
    }
  }
}

.content {
  @include run-design-card-content;
}


.select {
  --select-font-size: var(--run-design-card-inputs-default-font-size);
  width: 100%;
  min-width: var(--run-design-cell-column-content-default-min-width);
  height: 100%;
  background-color: var(--color-white);
  border-radius: 10px;

  &__control {
    height: 55px;
  }

  &__menu {
    --select-menu-margin-block: -50px -50px;
  }

  &__menu-list {
    &#{&} {
      padding: 0;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    height: 35px;
  }
}

.media-setting {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: var(--run-design-cell-column-content-default-min-width);
  height: 55px;
  padding: 7px;
  overflow: hidden;
  font-size: var(--run-design-card-inputs-default-font-size);
  text-overflow: ellipsis;
  background-color: var(--color-white);
  border-radius: 10px;
}

.add-info {
  &_incubation {
    position: absolute;
  }
}

.consumable {
  height: 55px;
}
