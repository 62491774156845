@import '@/styles/dashboard';

.dashboard {
  @include dashboard;

}

.design-experiment {
  --separator-color: var(--color-gray-80);

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }

  &__no-data {
    grid-column: span 3;
  }

  &__content {
    position: relative;
    padding-block: 20px;
  }

  &__separator {
    position: sticky;
    top: -88px;
    z-index: 2;
    height: 0;
    border-bottom: 1px solid var(--separator-color);
    translate: 0 150px;

    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 80px;
      content: '';
      background-color: var(--color-white);
    }
  }

  &__header-wrap {
    &._collapsed {
      --view-toggle-offset: 8px;
    }
  }

  &__header {
    padding-top: 8px;
    font-size: 50px;
    font-weight: bold;
    line-height: 55px;
    color: var(--color-black);
    letter-spacing: -.5px;
  }

  &__header-slash {
    margin-right: 3px;
    margin-left: 5px;
  }


  &__view-toggle {
    position: absolute;
    right: 0;
    bottom: var(--view-toggle-offset, 20px);
    display: flex;
    transition: bottom .3s ease-out;
  }

  &__templates,
  &__drafts,
  &__ready {
    --bg-color: var(--color-white-20);
    --color: var(--color-black);
    --counter-bg-color: var(--color-white);
    position: relative;
    z-index: 1;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 15px;
    font-size: 20px;
    font-weight: normal;
    color: var(--color);
    letter-spacing: .25px;
    background-color: var(--bg-color);
    border: 2px solid var(--bg-color);
    border-radius: 25px;
    transition: background-color .3s ease-out, color .3s ease-out, border-color .3s ease-out;

    &:hover {
      border: 2px solid var(--color-black);
    }

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &._active {
      --bg-color: var(--color-black);
      --color: var(--color-white);
      --counter-bg-color: var(--color-yellow-60);
    }
  }

  &__templates {
    margin-right: 12px;
  }

  &__counter {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 30px;
    font-size: 17px;
    color: var(--color-black);
    background-color: var(--counter-bg-color);
    border-radius: 15px;
    transition: background-color .3s ease-out;
    padding-inline: 11px;
  }

  &__view-toggle-spacer {
    position: relative;
    width: 4px;

    &::before {
      position: absolute;
      top: 0;
      right: -50px;
      bottom: 0;
      left: -50px;
      content: '';
      background-color: var(--color-white-20);
    }
  }
}

.card-placeholder {
  height: 160px;
}
