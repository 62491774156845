.header {
  display: grid;
  grid-template-columns: var(--grid-template-columns, unset);
  color: var(--color-white);
  background-color: var(--color-black-20);
}

.header-base {
  display: flex;
  grid-column: var(--grid-column, unset);
  align-items: center;
  justify-content: space-between;
  overflow-y: hidden;
  border-left: 1px solid var(--color-white);

  &:first-of-type {
    border-left: unset;
  }
}

.header-side {
  overflow-y: hidden;
  border-left: 1px solid var(--color-white);
}

.expand {
  margin-right: 10px;
  background-color: inherit;
  border-color: transparent;
}