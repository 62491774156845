.remove {
  display: flex;
  align-items: center;
  height: 100%;


  &__btn {
    --border-color: transparent;
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    color: var(--color-black);
    text-transform: uppercase;
    letter-spacing: .5px;
    transition: background-color .3s ease-out, opacity .3s ease-out;

    &_hidden {
      pointer-events: none;
      opacity: 0;
    }

    &:hover,
    &:active {
      --border-color: var(--color-black);
      --bg-color: var(--color-yellow-60);
    }

    &:focus-visible {
      outline: none;
    }

    &::before {
      position: absolute;
      inset: 0;
      width: 55px;
      height: 55px;
      content: '';
      background-color: var(--bg-color, var(--color-white));
      border: 1px solid var(--border-color);
      border-radius: 50%;
      transition: border-color .3s ease-out, background-color .3s ease-out;
    }

    &:focus-visible::before {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }
  }

  &__icon {
    z-index: 1;
    flex-shrink: 0;
    width: 55px;
    height: 55px;
  }
}

.dialog {
  width: clamp(420px, 60vw, 840px);
  min-height: 320px;
  aspect-ratio: 2;
}

.confirmation {
  display: grid;
  grid-template-rows: 1fr 110px;
  padding: 20px 20px 0;


  &__controls {
    display: flex;
    gap: 12px;
    align-items: center;
    border-top: 1px solid var(--color-black-20);
  }

  &__button {
    height: 70px;
    font-size: 20px;
    color: var(--color-black);
    border: none;
    transition: box-shadow .3s ease-out, background-color .3s ease-out;

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &:disabled {
      color: var(--button-color-disabled);
      pointer-events: all;
      cursor: not-allowed;
      box-shadow: 0 0 0 1px var(--button-bg-color-disabled);
    }

    &_cancel {
      display: flex;
      justify-content: center;
      font-weight: normal;
      box-shadow: inset 0 0 0 1px var(--color-black);

      &:hover:enabled,
      &:active:enabled {
        background-color: var(--color-yellow-60);
        box-shadow: inset 0 0 0 2px var(--color-black);
      }
    }

    &_confirm {
      font-weight: 600;
      background-color: var(--color-yellow-60);
      box-shadow: inset 0 0 0 1px var(--color-yellow-60);
      transition: box-shadow .3s ease-out;

      &:hover:enabled,
      &:active:enabled {
        background-color: var(--color-yellow-60);
        box-shadow: inset 0 0 0 2px var(--color-black);
      }
    }
  }

  &__title {
    margin-bottom: 14px;
    font-size: 33px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.5px;
  }

  &__text {
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0.25px;
  }

  &__tag {
    --tag-color: #CFF3FB;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    text-transform: uppercase;
    background-color: var(--tag-color);
    border-radius: 15px;
    padding-inline: 11px;
    translate: 0 -3px;

    &_current {
      background-color: var(--color-yellow-60);
    }
  }
}