.timeline-arrow {
  all: unset;
  display: flex;
  align-items: center;
  align-self: end;
  justify-content: center;
  width: 50px;
  height: 55px;
  color: var(--color-white);
  cursor: pointer;
  background-color: var(--color-blue-40);
  transition: background-color 0.3s ease-out, color 0.3s ease-out;

  &:hover {
    color: var(--color-black);
    background-color: var(--color-yellow-70);
  }
}