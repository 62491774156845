@import '@/styles/variables';

.global-settings {
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    font-size: 20px;
    background-color: var(--color-black-20);
    border-bottom: solid 1px var(--color-black);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__reset-btn {
    all: unset;
    display: flex;
    gap: 10px;
    align-items: center;
    padding-right: 10px;
    color: var(--color-white);
    cursor: pointer
  }

  &__content {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 40px;
    border-left: 1px solid var(--color-white-5);
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 35px;
  width: 100%;
  font-size: 16px;

  &_clickable {
    cursor: pointer;
  }
}

.info {
  padding-top: 20px;
  font-size: 16px;

  &__text {
    color: var(--color-gray-70);
    padding-inline: 5px;
  }
}

.custom-popover {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 5px;
  
  &__values {
    display: flex;
    flex-direction: column;
    line-height:    22.67px;
  }

  &__key {
    color: var(--color-gray-70);
  }
}

.table {
  width: 100%;
}
