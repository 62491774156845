.schema-default-component {
  --incubation-bg-color: #CFF3FB;
  --scan-bg-color: #38DDFE;
  --step-scan-color: var(--color-black);

  &::before {
    position: absolute;
    inset: 0;
    content: '';
    border: 2px solid transparent;
    transition: border-color .3s ease-out;
  }

  &:hover,
  &:active {
    &::before {
      border: 2px solid var(--scan-bg-color);
    }
  }

  &_current {
    --incubation-bg-color: var(--color-yellow-60);
    --scan-bg-color: var(--color-black-20);
    --step-scan-color: var(--color-white);
  }
}
