.add-component {
  display: flex ;
  align-self: flex-end;
  height: 100%;
  border-left: 1px solid var(--color-gray-80);

  &__select {
    width: 100%;
    border-top-right-radius: 10px;
  }

  &__step-list-wrap {
    border-top: 1px solid var(--color-gray-80);
  }
}