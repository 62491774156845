.content {
  display: grid;
  grid-template-columns: var(--grid-template-columns, unset);
}

.content-base {
  grid-column: var(--grid-column, unset);
  overflow: auto;
  border-left: 1px solid var(--color-gray-80);

  &:first-of-type {
    border-left: unset;
  }
}

.content-side {
  --grid-content-side-column: unset;
  display: grid;
  grid-column: var(--grid-content-side-column, unset);
  background-color: var(--color-white);
  border-left: 1px solid var(--color-gray-80);
}