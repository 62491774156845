.page-controls {
  --button-color-disabled: var(--color-gray-55);
  --button-bg-color-disabled: var(--color-white-15);

  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &__group {
    display: flex;
    gap: 10px;
  }

  &__button {
    height: 70px;
    font-size: 20px;
    color: var(--color-black);
    border: none;
    transition: box-shadow .3s ease-out, background-color .3s ease-out;

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &:disabled {
      color: var(--button-color-disabled);
      pointer-events: all;
      cursor: not-allowed;
      box-shadow: 0 0 0 1px var(--button-bg-color-disabled);
    }
  }

  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: inset 0 0 0 1px var(--color-black);

    &:hover:enabled,
    &:active:enabled  {
      background-color: var(--color-yellow-70);
      box-shadow: inset 0 0 0 2px var(--color-black);
    }
  }
  
  &__revert,
  &__save {
    --save-border-color: var(--color-gray-80);
    width: 205px;
    font-weight: normal;
    box-shadow: inset 0 0 0 1px var(--save-border-color);

    &:hover:enabled,
    &:active:enabled {
      background-color: var(--color-yellow-70);
      box-shadow: inset 0 0 0 2px var(--color-black);
    }
  }

  &__revert {
    gap: 10px;
  }

  &__cancel,
  &__edit {
    display: flex;
    justify-content: space-around;
    width: 140px;
    font-weight: normal;
    box-shadow: inset 0 0 0 1px var(--color-black);

    &:hover:enabled,
    &:active:enabled {
      background-color: var(--color-yellow-70);
      box-shadow: inset 0 0 0 2px var(--color-black);
    }
  }
  
  &__continue {
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    width: 440px;
    font-weight: 600;
    background-color: var(--color-yellow-70);
    box-shadow: inset 0 0 0 1px var(--color-yellow-70);
    transition: box-shadow .3s ease-out;

    &__title {
      grid-column: 1/span 2;
    }


    &:hover:enabled,
    &:active:enabled {
      background-color: var(--color-yellow-70);
      box-shadow: inset 0 0 0 2px var(--color-black);
    }

    &:disabled {
      background-color: var(--color-white-15);
    }
  }
}