.fov-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px 20px;
  font-size: 15px;
  font-weight: 500;

  &__info {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}