.lane-progress {
  --progress-color: var(--template-progress-color, var(--color-white-20));
  --progress-color-selected: var(--template-progress-color-selected, var(--color-yellow-40));
  --text-color: var(--template-text-color, var(--light-gray));
  --text-color-selected: var(--template-text-color-selected, var(--color-black));
  --border-color: var(--template-border-color, var(--light-color-gray-10));

  all: unset;
  display: grid;
  grid-template-columns: 25px 1fr;
  gap: 5px;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  color: var(--text-color);
  transition: color .3s ease-out, background-color .3s ease-out;

  &:hover,
  &_selected {
    --progress-color: var(--progress-color-selected);
    --text-color: var(--text-color-selected);
    cursor: pointer;

    &:hover {
      --progress-color: var(--progress-color-selected-hover, var(--color-yellow-40));
    }
  }

  &_disabled:hover {
    --progress-color: var(--template-progress-color-disabled, var(--color-white-20));
    --text-color: var(--template-text-color-disabled, var(--light-gray));
    cursor: default;
  }

  &__letter {
    flex-shrink: 0;
    width: 1ch;
  }

  &__progress {
    height: 100%;
    background: var(--progress-color);
    transition: background-color .3s ease-out;
  }

  &__percentage {
    flex-shrink: 0;
    width: 4ch; // 100%
    text-align: right;
  }
  

  &__progressbar {
    position: relative;
    flex: 1;
    height: 20px;
    overflow: hidden;
    border: solid 1px var(--progress-color);
    border-radius: 13px;
    transition: border-color .3s ease-out;

    &._full-width {
      grid-column: 2 / span 2;
    }
  }

  &__pin {
    width: 20px;
    height: 20px;
    background-color: var(--progress-color);
    border-radius: 50%;

    &._hidden {
      display: none;
    }
  }

  &__bar-wrapper {
    position: relative;
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
