.item {
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  cursor: pointer;
  background-color: var(--color-blue-40);
  transition: background-color 0.3s ease-out, color 0.3s ease-out;

  &:hover {
    background-color: var(--color-blue-45);
  }

  &._selected {
    color: var(--color-black);
    background-color: var(--color-yellow-70);
  }

  &._dragging {
    opacity: 0.8;
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr 10px;
    gap: 10px;
    height: 55px;
    padding-top: 10px;
    padding-right: 5px;
    padding-left: 10px;
  }

  &__name {
    padding-bottom: 10px;
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    color: var(--title-color);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    white-space: nowrap;
  }

  &__dnd-dots {
    padding-top: 2px;
  }
}