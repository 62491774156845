.cell-cage-content-block {
  display: flex;
  align-items: center;
  padding-inline: 20px;
  margin-right: 20px;
}

.cell-cage-header-block {
  display: flex;
  align-items: center;
}

.title {
  width: 300px;
  border-right: 1px solid var(--color-gray-50);
}

.advanced {
  min-width: 100px;
  height: 30px;
  margin-inline: 20px;
  border: 1px solid var(--color-white);
  border-radius: 20px;
}