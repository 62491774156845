.swiper {
  display: flex;
  width: 100%;

  &__btn {
    all: unset;
    cursor: pointer;
  }

  &__content {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.swiper-item {
  display: flex;
}