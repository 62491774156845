.select-component {
  &__add-step-wrap {
    --option-height: 54px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 250px;
    background-color: var(--color-black);

    &._collapsed {
      --is-collapsed: 1;
      --list-row-template: var(--option-height);
      --list-gap: 0;
      --add-step-border-color: transparent;
      --add-step-bg-color: var(--color-yellow-70);
      --add-step-color: var(--color-black);
    }
  }

  &__add-step-list-wrap {
    position: relative;
  }

  &__add-step-list-wrapper {
    position: absolute;
    inset: 0;
    top: calc(var(--is-collapsed, 0) * 70px);
    bottom: auto;
    z-index: 3;
    background-color: var(--color-white);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.10);
    opacity: var(--is-collapsed, 0);
    transition: opacity .3s ease-out;
  }

  &__add-step-list {
    display: grid;
    grid-template-rows: repeat(4, var(--list-row-template, 0));
    gap: var(--list-gap, 0);
    transition: grid-template-rows .3s ease-out, gap .3s ease-out;
  }

  &__add-step {
    all: unset;
    box-sizing: border-box;
    padding-inline: 20px;
    width: 100%;
    height: var(--option-height);
    text-align: left;
    cursor: pointer;   

    &:hover,
    &:active {
      color: var(--color-black);
      background-color: var(--color-yellow-70);
    }

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }
    
  }

  &__add-step-elem {
    position: relative;
    padding-bottom: 5px;

    &::before {
      position: absolute;
      right: 10px;
      bottom: 0;
      left: 10px;
      height: 1px;
      content: "";
      background-color: var(--color-gray-80);
    }

    &:last-of-type {
      padding-bottom: unset;

      &::before {
        content: none;
      }
    }
  }


  &__add-step-trigger {
    position: relative;
    z-index: 4;
    width: 100%;
    height: var(--option-height);
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: var(--add-step-color, var(--color-white));
    text-align: left;
    text-transform: uppercase;
    letter-spacing: .5px;
    background-color: var(--add-step-bg-color, var(--color-black));
    box-shadow: inset 0 0 0 1px var(--add-step-border-color, var(--color-black));
    transition: background-color .3s ease-out, box-shadow .3s ease-out, margin-bottom .3s ease-out;

    &:hover,
    &:active {
      color: var(--color-black);
      background-color: var(--color-yellow-70);
    }

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }
  }

  &__add-step-title {
    padding-inline: 20px;
  }

  &__add-step-trigger-icon {
    justify-self: flex-start;
  }

}

.point {
  justify-self: center;
  width: 8px;
  height: 8px;
  background-color: var(--color-black);
}

.title {
  display: grid;
  grid-template-columns: 45px 1fr;
  align-items: center;
  font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
}