.timeline-card {
  --scan-width: clamp(80px, 30%, 150px);

  position: relative;
  grid-row: var(--row, 1);
  grid-column: var(--column, 1);
  height: 100%;
  min-height: 180px;
  text-align: left;


  &_no-scan{
    --scan-width: 1px;
    --scan-display: none;
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr var(--scan-width);
    height: 100%;
    cursor: pointer;
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 10px 10px;
    transition: background-color .3s ease-out;
  }

  &__icon-list {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 48px;
    padding-bottom: 5px;
  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__title {
    display: block;
    color: inherit;
    container-type: inline-size;
  }

  &__text {
    @container (max-width: 90px) {
      --abbreviation-display: inline;
      display: none;
    }

    &_abbreviation {
      display: var(--abbreviation-display, none);
    }
  }
}
