.table {
  --row-size: var(--template-row-size, 55px);
  width: max-content;
  height: max-content;
  border-collapse: collapse;

  &__head {
    position: relative;
    min-height: var(--row-size);
    color: var(--color-gray-70);
    border-bottom: 1px solid var(--color-white-5);
    border-bottom-style: solid;

    & td {
      padding-top: 10px;
      font-weight: 500;
      letter-spacing: 1.65px;
    }
  }

  &__body {
    & tr:first-child td {
      padding-top: 10px;
    }
  }

  td {
    &:last-child {
      padding-right: 0;
    }
  }
}

.cell {
  height: 100%;
}
