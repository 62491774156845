.flowcell-type-select {
  --select-bg-color: var(--color-white-15);
  --select-border-width: 1px;
  --select-border-color: var(--color-white-15);
  --select-font-size: 20px;

  &_error {
    --select-border-color: var(--color-red-error);
  }

  &__control {
    height: 55px;
    padding: 0 5px;
  }

  &__menu {
    --select-menu-margin-block: -55px;
  }

  &__menu-list {
    &#{&} {
      padding: 0;
    }
  }
}
