.component-header {
  display: grid;
  grid-template-columns: 65px 1fr;

  &__wrapper {
    display: flex;
    grid-column: 2 / span1;
    border-bottom: 1px solid var(--light-color-gray-10);
  }
  
  &__title {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    height: 85px;
    font-size: 50px;
  }

  &__content {
    display: flex;
    height: 85px;
  }
}

.vertical-line {
  margin-inline: 20px;
  margin-block: 10px;
  width: 1px;
  background-color: var(--color-gray-80);
}