.edit-settings {
  &__table {
    padding-inline: 20px;
  }

  &__header {
    border-bottom: none;
  }
}

.head {
  &__item {
    font-size: 14px;
    text-transform: uppercase;

    &_advanced {
      text-align: center;
    }
  }

  &__reagent {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.magnification-table {
  width: 100%;
}

.magnification-table-block {
  margin-inline: 20px;
}
