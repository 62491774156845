.button {
  all: unset;
  width: 100%;
  cursor: pointer;
}

.title {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-white);
  transition: background-color 0.3s ease-out;
  padding-inline: 10px;

  &.clickable:hover {
    background-color: var(--color-black-30);
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 55px;

  &_center {
    justify-content: center;
    width: 55px;
  }
  
  &__svg {
    width: 22px;
  }
}
