.component-selector {
  --grid-template-columns: 1fr 240px;

  position: relative;
  display: grid;
  flex-shrink: 0;
  grid-template-columns: var(--grid-template-columns);
  padding-inline: 20px 10px;
  gap: 10px;
  width: 100%;

  &::before {
    position: absolute;
    right: 20px;
    bottom: 0;
    left: 20px;
    content: '';
    border-bottom: 1px solid var(--color-gray-80);
  }

  &__table {
    display: grid;
    grid-template-columns: repeat(var(--column-count,1), 1fr);
    grid-auto-rows: 1fr;
    grid-auto-columns: 1fr;
    gap: 2px;
    overflow: auto;
    padding-block: 20px;
  }

  &__add-step-wrap {
    --option-height: 54px;

    position: relative;

    &._collapsed {
      --is-collapsed: 1;
      --list-row-template: var(--option-height);
      --list-gap: 10px;
      --add-step-border-color: transparent;
      --add-step-bg-color: var(--color-white-15);
    }
  }

  &__add-step-list-wrap {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 2;
    width: 100%;
    padding: 10px;
    background-color: var(--color-white);

    &::before {
      position: absolute;
      inset: 0;
      content: '';
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.10);
      opacity: var(--is-collapsed, 0);
      transition: opacity .3s ease-out;
    }
  }

  &__add-step-list {
    position: relative;
    display: grid;
    grid-template-rows: repeat(4, var(--list-row-template, 0));
    gap: var(--list-gap, 0);
    opacity: var(--is-collapsed, 0);
    transition: grid-template-rows .3s ease-out, gap .3s ease-out, opacity .3s ease-out;
  }

  &__add-step {
    position: relative;
    width: 100%;
    height: var(--option-height);
    padding-left: 58px;
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: .5px;
    background-color: var(--color-white-15);

    &::before {
      position: absolute;
      top: calc(50% - 4px);
      left: 20px;
      width: 8px;
      height: 8px;
      content: '';
      background-color: var(--color-black);
    }

    &:hover,
    &:active {
      background-color: var(--color-yellow-60);
    }

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }
  }

  &__add-step-trigger {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--option-height);
    padding-left: 58px;
    margin-bottom: calc(var(--is-collapsed, 0) * 20px);
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .5px;
    background-color: var(--add-step-bg-color, var(--color-white));
    box-shadow: inset 0 0 0 1px var(--add-step-border-color, var(--color-black));
    transition: background-color .3s ease-out, box-shadow .3s ease-out, margin-bottom .3s ease-out;

    &:hover,
    &:active {
      background-color: var(--color-yellow-60);
    }

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: -10px;
      left: 0;
      content: '';
      border-top: 1px solid var(--color-gray-80);
      opacity: var(--is-collapsed, 0);
      transition: opacity .3s ease-out;
    }
  }

  &__add-step-trigger-icon {
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    left: 20px;
  }
}
