.default-option {
  position: relative;
  display: grid;
  grid-template-columns: 9px 1fr;
  gap: 10px;
  align-items: center;
  min-height: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  padding-inline: 12px;

  &:hover {
    background-color: var(--color-yellow-60);
  }

  &._selected {
    --selected-option-font-weight: 500;
    --selected-option-checkmark-opacity: 1;
  }

  &:not(&:last-child)::before {
    position: absolute;
    right: 10px;
    bottom: 0;
    left: 10px;
    height: 0;
    content: '';
    border-bottom: 1px solid var(--color-gray-80);
    opacity: 0.9;
  }

  &__checkmark {
    opacity: var(--selected-option-checkmark-opacity, 0);
    transition: opacity 0.3s ease-out;
  }

  &__content {
    display: flex;
    align-items: center;
    font-family: 'Inter Display', Arial, sans-serif;
    font-size: 20px;
    font-weight: var(--selected-option-font-weight, normal);
    color: var(--color-black);
  }
}
