.run-design-block {
  padding-block: 15px;

  &._new-flow {
    display: grid;
    grid-template-columns: 65px 1fr;
  }
}

.table {
  margin-top: 112px;
}
