.title-header {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 15px;
  font-size: 20px;
  color: var(--color-black-20);

  &__title {
    font-size: 50px;
    font-weight: bold;
    color: var(--color-black-20);
  }

  &__metadata {
    display: flex;
    gap: 40px;
    margin-top: 10px;
  }

  &__metadata-divider {
    width: 1px;
    background-color: var(--color-gray-80);
  }

  &__metadata-item {
    display: flex;

    &_title {
      color: var(--color-gray-70);
    }

  }
}
