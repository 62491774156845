.menu {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  color: var(--color-black);
  cursor: pointer;
  background-color: var(--color-white-15);
  border-radius: 50%;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: var(--color-gray-80);
  }

  &._open {
    color: var(--color-white);
    background-color: var(--color-black-20);
  }

  &._open:hover {
    color: var(--color-white);
    background-color: var(--color-black);
  }

}

.popover-wrapper {
  align-self: flex-start;
}