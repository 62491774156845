.template-header {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 0 10px 15px;
  font-size: 16px;
  font-weight: normal;
  color: var(--color-black-20);
  border-bottom: 1px solid var(--color-black-20);
}
