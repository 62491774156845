.schema-component {
  --step-scan-width: 90px;
  --step-height: 54px;

  position: relative;
  grid-row: var(--row, 1);
  grid-column: var(--column, 1);
  height: var(--step-height);
  cursor: pointer;
  background-color: var(--incubation-bg-color);
  transition: background-color .3s ease-out;


  &:focus-visible {
    outline: 2px solid var(--focus-color);
  }

  &_no-scan {
    --step-scan-width: 2px;
  }

  &__drop-wrap {
    position: absolute;
    inset: 0;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &__drop-zone {
    &._is-over {
      --icon-opacity: 0.9;
    }
  }

  &__drop {
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--color-white);
    border-radius: 10px 0 0 10px;
    translate: 0 -50%;
    opacity: var(--icon-opacity, 0);
    transition: opacity .3s ease-out;

    &_before {
      left: 0;
      scale: -1 1;
    }

    &_after {
      right: 0;
      left: auto;
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr var(--step-scan-width);
    height: 100%;
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: .5px;
  }

  &__step {
    min-width: 50px;
    height: 100%;
    padding-top: 11px;
    padding-left: 14px;
    text-align: left;
    container-type: size;
  }

  &__incubation {
    position: relative;
    z-index: 1;
  }

  &__scan {
    position: relative;
    z-index: 1;
    color: var(--step-scan-color);
    background-color: var(--scan-bg-color);
    transition: color .3s ease-out, background-color .3s ease-out;
  }

  &_cell-killing &__scan {
    position: absolute;
    right: 0;
    width: var(--step-scan-width);
  }

  &__name {
    @container (max-width: 120px) {
      --abbreviation-display: inline;
      display: none;
    }

    &_abbreviation {
      display: var(--abbreviation-display, none);
    }
  }
}
