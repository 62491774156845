@import '@/styles/variables';

.settings-card {
  overflow-x: overlay;
  background-color: var(--color-white-15);
}

.header {
  width: 100%;

  &__content {
    --expand-btn-width: 50px;

    position: sticky;
    left: 20px;
  }

  &__actions {
    position: sticky;
    right: 0;
    height: 100%;
    background-color: var(--color-black);
  }
}

.content {
  @include run-design-card-content;
  min-width: 100%;
  padding-inline: 20px;

  &_no-overflow-scroll {
    width: max-content;
    overflow: hidden;
  }
}

.header-sections {
  --small-column-width: 135px;
  display: flex;
  gap: 30px;

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
      position: absolute;
      top: 0;
      right: -15px;
      width: 1px;
      height: 100%;
      content: '';
      background-color: var(--color-white-5);
    }

    &:last-child::after {
      visibility: hidden;
    }
  }

  &__title {
    align-self: center;
  }

  &__view-more {
    --button-border-color: var(--color-white);
    min-width: 100px;
    height: 30px;
    margin-right: 20px;
    border: solid 1px;
    border-radius: 20px;
  }

  &__btn-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: max-content;
    height: 100%;
  }

  &__divider {
    width: 1px;
    height: 100%;
    background-color: var(--color-gray-70);

    &_right {
      margin-left: auto;
    }
  }

  &__icon {
    display: flex;
    color: var(--color-white);
  }
}
