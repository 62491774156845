.incubation-base-block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  height: 100%;

  &__column {
    padding: 0 20px 20px;
    margin-top: 20px;
    border-right: 1px solid var(--color-gray-80);
  }
}

.group {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--color-gray-80);

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }

  &__title {
    padding-bottom: 15px;
    overflow: hidden;
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-overflow: ellipsis;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    white-space: nowrap;
  }

  &__inputs {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 55px;
  }

  &__input-wrap {
    flex-grow: 1;
  }

  &__input {
    height: 55px;
    font-size: 20px;
    font-weight: 500;
    background-color: var(--color-white-15);
    border: none;
  }

  &__checkbox-wrap {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 55px;
  }

  &__checkbox-label {
    flex-grow: 1;
  }
}

.select {
  --select-bg-color: var(--color-white-15);
  --select-border-color: var(--color-white-15);
  --select-border-width: 1px;
  --select-font-size: 20px;

  width: 280px;
  font-weight: 500;
  color: var(--color-black);

  &_narrow {
    width: 100px;
  }
}

.select-control {
  height: 55px;
  padding: 0 5px;
}

.select-menu {
  --select-menu-margin-block: -55px;
}

.select-menu-list {
  &#{&} {
    padding: 0;
  }
}
