.page-panel {
  height: 100%;
  overflow: hidden;

  &__header {
    color: var(--color-black-20);
  }

  &__header-wrap {
    --theme-panel-header-font-size: 20px;

    padding: 20px 30px 0;
  }

  &__content {
    position: relative;
    gap: 0;
    padding: 0 25px 0 30px;
    overflow: auto;
    scrollbar-gutter: stable;
    scroll-behavior: smooth;
  }

  &__loader {
    --main-color: var(--color-black-20);
    margin-top: 20px;
  }

  &__footer {
    flex-shrink: 0;
    height: 110px;
    border-top: 1px solid var(--color-black-20);
    margin-inline: 30px;
  }
}