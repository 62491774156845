.counter-module {
  display: grid;
  grid-template-rows: 75px 28px;
  grid-template-columns: 1fr 104px;
  gap: 8px;
  padding: 20px 20px 16px;

  &__counter {
    margin-top: -13px;
    font-size: 90px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -.5px;
    transform: translateX(-2px);
  }

  &__description {
    grid-column-end: span 2;
    overflow: hidden;
    font-size: 20px;
    font-weight: normal;
    text-overflow: ellipsis;
    letter-spacing: .25px;
    white-space: nowrap;
    translate: 0 -3px;
  }

  &__controls {
    display: flex;
    gap: 4px;
    justify-content: flex-end;
  }

  &__lock {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__button {
    --btn-background-color: var(--template-btn-background-color, var(--color-yellow-60));
    --btn-border-color: var(--template-btn-border-color, var(--color-yellow-60));

    position: relative;
    display: grid;
    place-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--btn-background-color);
    border: 2px solid var(--btn-border-color);
    border-radius: 50%;
    transition: border-color .3s ease-out;

    &:hover,
    &:active {
      --btn-background-color: var(--template-btn-background-color-hover, var(--color-yellow-60));
      --btn-border-color: var(--template-btn-border-color-hover, var(--color-black-10));
    }

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &_disabled {
      --btn-background-color: var(--template-btn-background-color-disabled, var(--color-white-15));
      --btn-border-color: var(--template-btn-border-color-disabled, var(--color-white-15));

      pointer-events: none;
    }
  }
}

// TODO: NEWFLOW this styles of new design of lanes total, need change original styles after approve design

.counter-module-new {
  position: relative;
  display: flex;
  gap: 15px;
  align-items: center;

  &__counter {
    width: 45px;
    font-size: 70px;
    font-weight: bold;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__actions {
    display: flex;
    gap: 5px;
  }

  &__count-description {
    display: flex;
    align-items: center;
  }

  &__lock {
    position: absolute;
    top: 20px;
    right: -10px;
  }
}
