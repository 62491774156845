.type-sample-loading {
  &__wrapper {
    display: flex;
    align-items: center;
  }
}

.lanes {
  &::before {
    content: none;
  }
}