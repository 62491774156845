.timeline {
  --heading-color: #b6b6b6;
  --heading-bg-color: #323232;
  --heading-separator-color: #7f7f7f;
  --heading-reducer-strip-color: var(--color-white);
  --row-bg-color: var(--color-black-20);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__heading {
    display: grid;
    grid-template-columns: repeat(var(--column-count, 1), minmax(0, 1fr));
    width: 100%;
    height: 60px;
    background-color: var(--heading-bg-color);
  }

  &__time-block {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding-left: 5px;

    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 38px;
      content: '';
      border-right: 1px solid var(--heading-separator-color);
    }

    &:last-child::before {
      opacity: 0;
    }
  }

  &__time {
    font-family: SFMono, Arial, Helvetica, sans-serif;;
    font-size: 15px;
    font-weight: 500;
    color: var(--heading-color);
    letter-spacing: .5px;
  }

  &__time-reducer {
    position: absolute;
    top: 0;
    right: 15px;
  }

  &__body {
    display: grid;
    flex-grow: 1;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(var(--column-count, 1), minmax(0, 1fr));
    row-gap: 2px;
    background: var(--row-bg-color);
  }
}
