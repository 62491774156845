.label {
  --letter-color: var(--color-white);
  --letter-background-color: var(--color-black);
  --letter-border-color: var(--color-black);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  font-size: 20px;
  color: var(--letter-color);
  background-color: var(--letter-background-color);
  border: 1px solid var(--letter-border-color);
  border-radius: 50%;

  &_non-existent {
    --letter-color: var(--color-black);
    --letter-background-color: var(--color-white);
    --letter-border-color: var(--color-black);
  }

  &_non-editable {
    cursor: default;

    &_recolored {
      --letter-border-color: var(--color-white-10);
    }
  }
}
