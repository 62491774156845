

.header {
  align-items: center;

  &__switch {
    --switch-height: 55px;
    --line-height: 55px;
    --font-size: 20px;
    --background-color: var(--color-white-20);
  }
}

.block-header {
  padding-inline: 10px;
}

.global-settings {
  &__panel-header {
    padding-inline: unset;
  }

  &__panel-content {
    padding: 20px;
    background-color: var(--color-white);
    border-left: 1px solid var(--light-color-gray-101);
  }
}

